@import "./app/App.css";
@import "./header/Header.css";
@import "./advertising/Banner.css";
@import "./tabs/TabSwitcher.css";
@import "./tabs/TabContent.css";
@import "./tabs/TabHeader.css";
@import "./breed/BreedSelector.css";
@import "./calculator/PetAgeCalculator.css";
@import "./calculator/ByActualAgePetAgeCalculator.css";
@import "./calculator/Display.css";
@import "./footer/Footer.css";
@import "./calculator/ByBirthDatePetAgeCalculator.css";
@import "react-datepicker/dist/react-datepicker.css";

@media only screen and (max-width: 1024px) {
    .form-field {
        width: 100%;
    }
}

@media only screen and (min-width: 1025px) {
    .form-field {
        width: 20rem;
    }
}


body {
    width: 80%;
    margin: 0 auto;
    min-height: 100vh;
}

#root {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

.form-field {
    box-sizing: border-box;
    border-radius: 0.5rem;
    border: 0.0625rem solid #979797;
}

.form-field input {
    width: 100%;
    height: 2rem;
    border: none;
    border-radius: 0.5rem;
    font-size: 0.875rem;
    font-family: 'Noto-Sans', sans-serif;
    text-overflow: ellipsis;
    text-align: left;
    padding: 0;
    padding-left: 1rem;
    box-sizing: border-box;
}

.is-required-form-field {
    border: 0.0625rem solid #CE2029;
}

.is-required-form-field input::placeholder {
    color: #CE2029;
}

.hidden {
    display: none;
}

.is-required {
  color: #CE2029;
}