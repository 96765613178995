@media only screen and (max-width: 1024px) {
    .tab-content:not(.hidden) {
        display: flex;
        flex-grow: 1;
    }
}

@media only screen and (min-width: 1025px) {
    .tab-content:not(.hidden) {
        display: flex;
        flex-grow: 1;
    }
}

.tab-contents {
  display: flex;
  flex-grow: 1;
}