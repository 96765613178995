@media only screen and (max-width: 1024px) {
    .age-calculator-container {
        flex-direction: column;
        padding: 2rem 0;
    }

    .age-calculator-form-container {
        display: flex;
        flex-grow: 1;
        flex-direction: column;
        justify-content: space-evenly;
    }

    .age-calculator-buttons {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      justify-content: space-evenly;
    }    
}

@media only screen and (min-width: 1025px) {
    .age-calculator-form-container {
        display: flex;
        flex-grow: 1;
        flex-direction: column;
        justify-content: space-evenly;
        flex-basis: 0;
    }

    .age-calculator-buttons {
      display: flex;
      flex-grow: 1;
      justify-content: space-evenly;
    }
}

.age-calculator-container {
    display: flex;
    flex-grow: 1;
    min-height: 20rem;
}

.age-calculator-buttons button {
  display: inline-block;
  width: 9rem;
  height: 2rem;
  background-color: #F4AA41;
  color: #fff;
  font-family: 'Noto-Sans', sans-serif;
  border-radius: 0.5rem;
  border: 0rem;
  align-self: center;
}