@media only screen and (max-width: 1024px) {
    .tab-headers {
        display: flex;
    }

    .tab-headers .tab-header {
        flex-grow: 1;
        margin: 0;
        text-align: center;
    }

    .tab-headers-back-button {
        display: inline-block;
        box-sizing: border-box;
        border-left: 0.0625rem solid black;
        border-bottom: 0.0625rem solid black;
        width: 1rem;
        height: 1rem;
        transform: rotate(45deg);
        position: relative;
        top: 0.375rem;
        left: 0.375rem;
    }

    .tab-headers-forward-button {
        display: inline-block;
        box-sizing: border-box;
        border-top: 0.0625rem solid black;
        border-right: 0.0625rem solid black;
        width: 1rem;
        height: 1rem;
        transform: rotate(45deg);
        position: relative;
        top: 0.375rem;
        right: 0.375rem;
    }

    .tab-headers .tab-header:not([data-selected='true']) {
        display: none;
    }

    .tab-headers-button-disabled {
        border-color: gray;
    }
}

@media only screen and (min-width: 1025px) {
    .tab-headers-back-button, .tab-headers-forward-button {
        display: none;
    }

    .tab-switcher {
        display: flex;
        flex-direction: column;
        border: 0.06rem solid #979797;
    }
    
    .tab-switcher .tab-headers {
        display: flex;
        cursor: pointer;
        overflow-x: auto;
    }
    
    .tab-switcher .tab-headers .tab-header {
        white-space: nowrap;
        flex-basis: 0;
        flex-grow: 1;
        text-align: center;
        display: inline-block;
        margin: 0;
        padding: 1rem 0.5rem;
    }
    
    .tab-switcher .tab-headers .tab-header:not(:first-of-type) {
        border-left: 0.06rem solid #979797;
    }
    
    .tab-switcher .tab-contents {
        display: flex;
        flex-grow: 1;
    }
}

.tab-switcher {
  display: flex;
  flex-direction: column;
  min-height: 40rem;
}


