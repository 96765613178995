@media only screen and (max-width: 1024px) {
  .by-actual-age-calculator-slider-container {
    width: 100%;
  }
}

@media only screen and (min-width: 1025px) {
  .by-actual-age-calculator-slider {
    width: 20rem;
  }
}

.by-actual-age-calculator-title {
  font-family: 'Noto-Sans', sans-serif;
  margin: 0;
}

.by-actual-age-calculator-slider-container {
  display: flex;
  flex-direction: column;
  height: 2.75rem;
}

.by-actual-age-calculator-container {
    display: flex;
    flex-grow: 2;
    flex-direction: column;
    justify-content: space-evenly;
    box-sizing: border-box;
    align-items: center;
}

.by-actual-age-calculator-slider-thumb {
  font-family: 'Noto-Sans', sans-serif;
  cursor: pointer;
  background-color: #F4AA41;
  color: white;
  font-size: 0.9rem;
  top: -0.75rem;
  text-align: center;
  border: 1px solid white;
  padding: 0.25rem;
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
}

.by-actual-age-calculator-slider {
  top: 0.75rem;
}

.by-actual-age-calculator-slider-track {
  height: 0.25rem;
  background-color: #F4AA41;  
  opacity: 0.3;
}