@media only screen and (min-width: 1025px) {
  .tab-header {
      font-family: 'Noto-Sans', sans-serif;
      font-size: 1.25rem;
      border-bottom: 0.25rem solid #F4AA41;
      display: inline-block;
  }
  
  .faded {
      color: #8e8e8e;
      border-bottom: 0.06rem solid #979797;
  }
}

@media only screen and (max-width: 1024px) {
  .tab-header {
    display: inline-block;
    font-family: 'Noto-Sans', sans-serif;
    font-size: 1.25rem;
  }
}