.by-birth-date-calculator-container {
    display: flex;
    flex-grow: 2;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}

.by-birth-date-calculator-datepicker-container {
    width: 100%;
    border-radius: 0.5rem;
    border: 0.0625rem solid #979797
}

.by-birth-date-calculator-container .react-datepicker__close-icon {
    padding-right: 1rem !important;
}

.by-birth-date-calculator-datepicker .react-date-picker__inputGroup {
    padding: 0;
    padding-left: 1rem;
}

.by-birth-date-calculator-datepicker .react-calendar {
    box-sizing: border-box !important;
    border-radius: 0.5rem !important;
    border: 0.0625rem solid #979797 !important;
    width: 20rem;
}

.react-datepicker * {
    border: none !important;
}

.react-datepicker input {
    width: 100%;
    height: 2rem;
    font-size: 0.875rem;
    font-family: 'Noto-Sans', sans-serif;
    text-overflow: ellipsis;
}

.react-datepicker, .react-datepicker__month-container {
    width: 20rem;
}