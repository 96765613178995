@media only screen and (max-width: 1024px) {
    .footer .languages {
        display: flex;
        flex-direction: column;
        margin: 0;
        padding: 0;
    }

    .footer .languages .language-list {
        display: flex;
        list-style-type: none;
        padding: 0;
        margin: 0;
        flex-wrap: wrap;
      }

    .language img {
        width: 4rem;
        height: 4rem;
    }

    .language .language-title {
        display: flex;
        align-items: center;
    }
}

@media only screen and (min-width: 1025px) {
    .footer .languages {
        display: none;
    }
}

.footer {
  display: flex;
  flex-direction: column;
}

.footer p {
  margin: 0;
  font-family: 'Noto-Sans', sans-serif;
}

.copyright {
  padding: 1rem 0;
  align-self: center;
}