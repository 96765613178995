@media only screen and (max-width: 1024px) {

}

@media only screen and (min-width: 1025px) {
    .age-calculator-result-container {
        flex-grow: 1;
    }
}

.age-calculator-result-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-grow: 1;
    flex-basis: 0;
}

.age-calculator-result-count-up, .age-calculator-result-count-up-label {
  font-family: 'Noto-Sans', sans-serif;
  display: inline-block;
  font-size: 2rem;
  flex-grow: 1;
  text-align: center;
}

.age-calculator-result-count-up-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.age-calculator-result-content-container {
  display: flex;
  flex-direction: column;
}

.age-calculator-result-title {
  font-family: 'Noto-Sans', sans-serif;
  box-sizing: border-box;
  padding: 2rem 0;
  display: inline-block;
  width: 100%;
  font-size: 2rem;
  text-align: center;
}

.age-calculator-result-data-container {
  display: flex;
}

.age-calculator-result-container p {
    margin: 0;
    font-family: 'Noto-Sans', sans-serif;
    font-size: 1.5rem;
}