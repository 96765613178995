@media only screen and (max-width: 1024px) {
    .header {
        display: flex;
    }
    
    .logo-container {
        display: flex;
        flex-grow: 1;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        gap: 0.5rem;
    }
    
    .logo-image {
        width: 4rem;
        height: 4rem;
    }
    
    .logo-title {
        font-family: 'Noto-Sans', sans-serif;
        display: inline-block;
        font-size: 2rem;
        margin: 0;
    }
    
    .i18n-container {
        display: none;
    }
}

@media only screen and (min-width: 1025px) {
    .header {
        display: flex;
    }
    
    .logo-container {
        display: flex;
        flex-grow: 2;
        align-items: center;
    }
    
    .logo-image {
        width: 4rem;
        height: 4rem;
    }
    
    .logo-title {
        font-family: 'Noto-Sans', sans-serif;
        display: inline-block;
        font-size: 2rem;
        margin: 0;
        padding-left: 1rem;
    }
    
    .i18n-container {
        display: flex;
        flex-grow: 1;
        justify-content: flex-end;
        
    }
    
    .i18n-container ul {
        display: flex;
        margin: 0;
        padding: 0;
    }
    
    .i18n-container li {
        display: inline-block;
    }
    
    .language img {
        width: 4rem;
        height: 4rem;
    }
}